<template>
  <div class="pd-content" ref="block7" style="padding-bottom:52px">
    <div class="swiper-box container-box">
      <div class="rec-pat container-box">
        <div class="right-box">
          <h6>医生社区</h6>
          <p class="p-text">
            通过多媒体把医生聚到一起，为医生提供行业内联络、信息交流的医疗行业社交网站。网站只对执业医师等专业人员开放，申请人必须完成医生端认证才能发布信息，加密处理用户数据保证客户安全隐私；医生社区支持病例讨论、前沿资讯讨论、经验干货分享等各种医学相关讨论帖子，讨论形式多样化包括文字、图片、视频等，网站内使用真实身份进行高水平专业探讨和病例合作诊断，实现职业价值最大化；在社区里医生可以聊天、互相关注、加为好友建立直接有效的沟通。
          </p>
          <!-- <div class="module-title">
                <img src="~@/assets/img/images/cl.png" alt="" />
                <span>核心功能</span>
              </div>
              <ul>
                <li>
                  <p class="subtitle">数字化切片扫描</p>
                  <p class="text">
                    扫描快速、图像清晰、多类型样本扫描、双物镜配置
                  </p>
                </li>
                <li>
                  <p class="subtitle">病例管理</p>
                  <p class="text">病人信息、临床信息、病嘱、历史病例</p>
                </li>
                <li>
                  <p class="subtitle">标本信息</p>
                  <p class="text">检查项目、取材部位、大小、数量</p>
                </li>
                <li>
                  <p class="subtitle">数字阅片</p>
                  <p class="text">放大、缩小、自动采图、快速检索病例</p>
                </li>
              </ul> -->
        </div>
        <div class="left-box">
          <!-- <img src="~@/assets/img/images/doctor.png" alt="" /> -->
          <img
            src="https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/doctor.png"
            alt=""
          />
        </div>
      </div>

      <!-- <div class="button-box">
        <img class="img1" src="~@/assets/img/images/active1.png" alt="" />
        <div
          class="dv-center"
          :style="{ width: `calc(${this.px}px*10px )` }"
        >
          <div class="swiper-pagination" slot="pagination"></div>
        </div>
        <img src="~@/assets/img/images/active2.png" alt="" />
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperOption2: {
        slidesPerView: 1,
        // loop: true,
        // effect: "fade",
        // fade: {
        //   crossFade: false
        // },
        // autoplay: {
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false,
        //   delay: 2000
        // },
        pagination: {
          el: ".swiper-pagination"
          // clickable: true
        },
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev"
        // },
        on: {
          slideChange: () => {}
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.swiper-box {
  position: relative;
  .button-box {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 99;
    left: 24px;
    bottom: 24px;
    img {
      cursor: pointer;
      width: 40px;
      height: 40px;
    }
    .dv-center {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    // .img1 {
    //   margin-right: 30px;
    // }
  }
}
.rec-pat {
  // margin-top: 80px;
  height: 480px;
  border: 1px solid #f0f0f0;
  display: flex;
  .left-box {
    width: 640px;
    height: 100%;
    // background: linear-gradient(
    //   234.87deg,
    //   #007aff 0%,
    //   rgba(255, 255, 255, 0) 70.65%
    // );
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right-box {
    padding: 24px;
    flex: 1;
    height: 100%;
    h6 {
      font-size: 24px;
      color: #262626;
      line-height: 34px;
    }
    .p-text {
      padding-top: 10px;
      font-size: 16px;
      line-height: 26px;
      color: #262626;
    }
    .module-title {
      padding-top: 30px;
      font-size: 16px;
      color: #262626;
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        margin-right: 2%;
        margin-top: 2%;
        width: 49%;
        height: 74px;
        background: #e9f3ff;
        padding: 8px 16px;
        .subtitle {
          font-size: 14px;
          color: #262626;
          line-height: 20px;
          padding-bottom: 8px;
        }
        .text {
          font-size: 12px;
          color: #595959;
          line-height: 16px;
        }
      }
      li:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>

<template>
  <div class="pd-content" ref="block6">
    <div class="rec-pat container-box">
      <div class="left-box">
        <!-- <img class="img p-img" src="~@/assets/img/images/h0.png" alt="" /> -->
        <img
          class="img p-img"
          src="https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/h0.png"
          alt=""
        />
        <swiper :options="swiperOption" ref="mySwiper8">
          <swiper-slide v-for="(item, index) in imgList" :key="item.id">
            <img class="img" :src="item.icon" alt="" />
          </swiper-slide>
          <!-- <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div> -->
        </swiper>
      </div>
      <div class="right-box">
        <h6>健康宣教</h6>
        <p>
          以统一管理方式将视音频、图片、滚动字幕等信息通过网络传输到显示屏，能够有效覆盖医院门诊大厅、病房、电梯间等场所；根据不同区域和受众群体，分级分区管理发布健康宣教内容。精准推荐、持续推送健康知识便于居民提高健康意识；系统提供对发布内容审核、发布、维护模块，对发布内容信息的权限严格控制，保证非授权人员不能篡改网页内容信息，发布内容后提供查询、反馈和统计渠道，对健康宣教数据进行多维度分析统计，为医疗机构持续改进健康宣教工作提供决策支持。
        </p>
        <div class="p-box">
          <div
            class="flow-box"
            :class="idx == '0' ? 'active' : ''"
            @click="toSlide(0, 1)"
          >
            <span class="sp1">1</span>
            <span class="sp2">应用端</span>
          </div>
          <div class="line"></div>
          <div
            class="flow-box"
            :class="idx == '1' ? 'active' : ''"
            @click="toSlide(1, 2)"
          >
            <span class="sp1">2</span>
            <span class="sp2">管理端</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      idx: 0,
      imgList: [
        {
          id: 1,
          icon: require("../assets/img/images/h1.png")
        },
        {
          id: 2,
          icon: require("../assets/img/images/h2.png")
        }
      ],
      swiperOption: {
        loop: true, // 循环
        speed: 500, //切换速度
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        centeredSlides: true,
        // slidesPerView: 1,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        on: {
          slideChange: () => {
            // console.log(this.swiper.realIndex, "realIndex");
            this.idx = this.swiper.realIndex;
          }
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper8.swiper;
    }
  },
  methods: {
    toSlide(idx, id) {
      this.idx = idx;
      this.swiper.slideTo(id, 1000, false);
    }
  }
};
</script>

<style lang="scss" scoped>
.rec-pat {
  // margin-top: 80px;
  height: 480px;
  border: 1px solid #f0f0f0;
  display: flex;
  .left-box {
    position: relative;
    width: 640px;
    height: 100%;
    // background: linear-gradient(
    //   153.43deg,
    //   #007aff 0%,
    //   rgba(255, 255, 255, 0) 83.33%
    // );
    .p-img {
      position: absolute;
      top: 0;
      left: 0;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right-box {
    position: relative;
    padding: 24px;
    flex: 1;
    height: 100%;
    h6 {
      font-size: 24px;
      color: #262626;
      line-height: 34px;
    }
    p {
      padding-top: 10px;
      font-size: 16px;
      line-height: 26px;
      color: #262626;
    }

    .p-box {
      position: absolute;
      bottom: 24px;
      left: 24px;
    }
    .flow-box {
      display: flex;
      align-items: center;
      cursor: pointer;
      .sp1 {
        width: 24px;
        height: 24px;
        background: #bfbfbf;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #fff;
        position: relative;
      }

      .sp2 {
        font-size: 14px;
        padding-left: 12px;
      }
    }
    .active .sp1 {
      background: #007aff;
    }
    .active .sp1::before {
      content: "";
      border-radius: 100%;
      border: 1px solid #007aff;
      width: 32px;
      height: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .active .sp2 {
      color: #262626;
    }
    .line {
      margin: 5px 11px;
      width: 2px;
      height: 15px;
      background: #bfbfbf;
    }
  }
}

// .left-box:hover .swiper-button-prev,
// .left-box:hover .swiper-button-next {
//   display: block;
// }
// .swiper-button-prev,
// .swiper-button-next {
//   display: none;
// }

.view-flow-mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  .view-flow-box {
    width: 720px;
    background: #fff;
    height: 600px;
    overflow: auto;
    .img1 {
      width: 100%;
      height: auto;
    }
    .img2 {
      width: 576px;
      height: 813px;
    }
  }
}

.view-flow-box::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.view-flow-box::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
.view-flow-box::-webkit-scrollbar-track {
  /*滚动条里面轨道  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);*/
  border-radius: 10px;
  background: #fff;
}
.first-box {
  position: relative;
  .close {
    width: 32px;
    height: 32px;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    right: 10px;
    cursor: pointer;
    img {
      width: 32px;
      height: 32px;
    }
  }
}
</style>

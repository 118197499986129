<template>
  <div class="pd-content" ref="block4">
    <div class="rec-pat container-box">
      <div class="left-box">
        <!-- <img src="~@/assets/img/images/fetal-heart.png" alt="" /> -->
        <img
          src="https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/fetal-heart.png"
          alt=""
        />
      </div>
      <div class="right-box">
        <h6>远程胎心监护</h6>
        <p class="p-text">
          运用超声多普勒胎心监测仪和移动终端，可以不受时间和空间限制进行胎心监护，数据处理后显示胎心率数值、宫缩压力数值、胎心率曲线，宫缩压力曲线、胎动数值、播放胎心音等，并且由专业医生远程判读，做到监测结果与医院一致，大大提高产检质量；实现院内外多床监护信息统一管理和上传，医护人员可在院内监护系统上查询孕产妇院外胎监数据；将传统胎心监护技术与移动通信技术、物联网技术融合，打造先进医疗管理模式提高服务品质。
        </p>
        <!-- <div class="module-title">
          <img src="~@/assets/img/images/cl.png" alt="" />
          <span>核心功能</span>
        </div>
        <ul>
          <li>
            <p class="subtitle">综合会诊</p>
            <p class="text">扫描快速、图像清晰、多类型样本扫描、双物镜配置</p>
          </li>
          <li>
            <p class="subtitle">会诊详情</p>
            <p class="text">病例信息、病人信息、医生信息、会议信息</p>
          </li>
          <li>
            <p class="subtitle">会诊服务</p>
            <p class="text">会诊病例、未签发病例、签发病例、医嘱病例</p>
          </li>
          <li>
            <p class="subtitle">会诊预约</p>
            <p class="text">创建、取消、参加、短信、移动通知</p>
          </li>
        </ul> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.rec-pat {
  // margin-top: 80px;
  height: 480px;
  border: 1px solid #f0f0f0;
  display: flex;
  .left-box {
    width: 640px;
    height: 100%;
    // background: linear-gradient(
    //   108.19deg,
    //   #007aff -0.53%,
    //   rgba(255, 255, 255, 0) 49.5%
    // );
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right-box {
    padding: 24px;
    flex: 1;
    height: 100%;
    h6 {
      font-size: 24px;
      color: #262626;
      line-height: 34px;
    }
    .p-text {
      padding-top: 10px;
      font-size: 16px;
      line-height: 26px;
      color: #262626;
    }
    .module-title {
      padding-top: 30px;
      font-size: 16px;
      color: #262626;
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        margin-right: 2%;
        margin-top: 2%;
        width: 49%;
        height: 74px;
        background: #e9f3ff;
        padding: 8px 16px;
        .subtitle {
          font-size: 14px;
          color: #262626;
          line-height: 20px;
          padding-bottom: 8px;
        }
        .text {
          font-size: 12px;
          color: #595959;
          line-height: 16px;
        }
      }
      li:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>

<template>
  <div class="pd-content" ref="block2">
    <div class="rec-pat container-box">
      <div class="left-box">
        <!-- <img src="~@/assets/img/images/telemedicine.png" alt="" /> -->
        <img
          src="https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/telemedicine.png"
          alt=""
        />
      </div>
      <div class="right-box">
        <h6>远程会诊系统</h6>
        <p class="p-text">
          系统为医生间远程实时沟通疑难病例提供支持，可随时申请会诊，PC端和移动端实现即时在线沟通，最后形成会诊意见为患者治疗方案提供方向。系统可实现患者电子病历信息、检查结果、医嘱等相关信息实时传输调阅，专家掌握所有的相关信息后进行会诊，大大提升诊断准确率，对会诊信息进行科学合理的流转、存储和统计分析，实现会诊过程的跟踪管理有利于提高患者满意度，医院间会诊促进医院功能延伸，增加医院影响力。
        </p>
        <div class="p-box">
          <div class="top-control">
            <div class="module-title">
              <img src="~@/assets/img/images/cl.png" alt="" />
              <span>核心功能</span>
            </div>
            <div class="btn-group">
              <img
                @click="toSlide(0, 1)"
                src="~@/assets/img/images/active1.png"
                alt=""
              />
              <img
                @click="toSlide(0, 2)"
                src="~@/assets/img/images/active2.png"
                alt=""
              />
            </div>
          </div>
          <swiper class="ul" :options="swiperOption3" ref="mySwiper3">
            <swiper-slide>
              <div class="li">
                <p class="subtitle">综合会诊</p>
                <p class="text">
                  扫描快速、图像清晰、多类型样本扫描、双物镜配置
                </p>
              </div>
              <div class="li">
                <p class="subtitle">会诊详情</p>
                <p class="text">病例信息、病人信息、医生信息、会议信息</p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="li">
                <p class="subtitle">会诊服务</p>
                <p class="text">会诊病例、未签发病例、签发病例、医嘱病例</p>
              </div>
              <div class="li">
                <p class="subtitle">会诊预约</p>
                <p class="text">创建、取消、参加、短信、移动通知</p>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      idx: 0,
      swiperOption3: {
        loop: true, // 循环
        speed: 500, //切换速度
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        centeredSlides: true,
        // slidesPerView: 1,
        on: {
          slideChange: () => {
            this.idx = this.swiper.realIndex;
          }
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper3.swiper;
    }
  },
  methods: {
    toSlide(idx, id) {
      console.log(idx, id);
      this.idx = idx;
      this.swiper.slideTo(id, 1000, false);
    }
  }
};
</script>

<style lang="scss" scoped>
.rec-pat {
  // margin-top: 80px;
  height: 480px;
  border: 1px solid #f0f0f0;
  display: flex;
  .left-box {
    width: 640px;
    height: 100%;
    // background: linear-gradient(
    //   108.19deg,
    //   #007aff -0.53%,
    //   rgba(255, 255, 255, 0) 49.5%
    // );
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right-box {
    padding: 24px;
    flex: 1;
    height: 100%;
    position: relative;
    h6 {
      font-size: 24px;
      color: #262626;
      line-height: 34px;
    }
    .p-text {
      padding-top: 10px;
      font-size: 16px;
      line-height: 26px;
      color: #262626;
    }
    .p-box {
      position: absolute;
      bottom: 24px;
      left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 24px;
      .top-control {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .module-title {
          font-size: 16px;
          color: #262626;
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
            width: 20px;
            height: 20px;
          }
        }
        .btn-group {
          display: flex;
          img {
            cursor: pointer;
            margin-left: 20px;
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    .ul {
      width: 100% !important;
      ::v-deep .swiper-slide {
        display: flex;
        width: 100%;
      }
      .li {
        margin-right: 2%;
        margin-top: 2%;
        width: 49%;
        height: 74px;
        background: #e9f3ff;
        padding: 8px 16px;
        .subtitle {
          font-size: 14px;
          color: #262626;
          line-height: 20px;
          padding-bottom: 8px;
        }
        .text {
          font-size: 12px;
          color: #595959;
          line-height: 16px;
        }
      }
      .li:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>

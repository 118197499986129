<template>
  <div class="pd-content" ref="block1">
    <div class="swiper-box container-box">
      <div class="rec-pat container-box">
        <div class="right-box">
          <h6>远程病理诊断</h6>
          <p class="p-text">
            利用数字化设备将物理切片扫描成数字化切片，系统将数字化切片上传到平台，专家通过平台完成阅片、诊断、签发报告后回传到基层医院。通过协作提高病理诊断水平，赋能临床诊疗；促进优质医疗资源下沉，助力分级诊疗推行；病理资料数字化保存为科研工作奠定基础；提供病理报告流程化管理有利于提升病案首页质量；通过浏览器登录即可进行远程诊断工作，不受空间和时间限制；统计分析病理样本质量、诊断结果准确率等为决策提供数据支撑。
          </p>

          <div class="p-box">
            <div class="top-control">
              <div class="module-title">
                <img src="~@/assets/img/images/cl.png" alt="" />
                <span>核心功能</span>
              </div>
              <div class="btn-group">
                <img
                  @click="toSlide(0, 1)"
                  src="~@/assets/img/images/active1.png"
                  alt=""
                />
                <img
                  @click="toSlide(0, 2)"
                  src="~@/assets/img/images/active2.png"
                  alt=""
                />
              </div>
            </div>

            <swiper class="ul" :options="swiperOption2" ref="mySwiper2">
              <swiper-slide>
                <div class="li">
                  <p class="subtitle">数字化切片扫描</p>
                  <p class="text">
                    扫描快速、图像清晰、多类型样本扫描、双物镜配置
                  </p>
                </div>
                <div class="li">
                  <p class="subtitle">病例管理</p>
                  <p class="text">病人信息、临床信息、病嘱、历史病例</p>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="li">
                  <p class="subtitle">标本信息</p>
                  <p class="text">
                    检查项目、取材部位、大小、数量
                  </p>
                </div>
                <div class="li">
                  <p class="subtitle">数字阅片</p>
                  <p class="text">放大、缩小、自动采图、快速检索病例</p>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="left-box">
          <!-- <img src="~@/assets/img/images/diagnose.png" alt="" /> -->
          <img
            src="https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/diagnose.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      idx: 0,
      swiperOption2: {
        loop: true, // 循环
        speed: 500, //切换速度
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        centeredSlides: true,
        // slidesPerView: 1,
        on: {
          slideChange: () => {
            this.idx = this.swiper.realIndex;
          }
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper2.swiper;
    }
  },
  methods: {
    toSlide(idx, id) {
      console.log(idx, id);
      this.idx = idx;
      this.swiper.slideTo(id, 1000, false);
    }
  }
};
</script>

<style lang="scss" scoped>
.swiper-box {
  position: relative;
}
.rec-pat {
  // margin-top: 80px;
  height: 480px;
  border: 1px solid #f0f0f0;
  display: flex;
  .left-box {
    width: 640px;
    height: 100%;
    background: linear-gradient(
      234.87deg,
      #007aff 0%,
      rgba(255, 255, 255, 0) 70.65%
    );
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right-box {
    position: relative;
    padding: 24px;
    flex: 1;
    height: 100%;
    h6 {
      font-size: 24px;
      color: #262626;
      line-height: 34px;
    }
    .p-text {
      padding-top: 10px;
      font-size: 16px;
      line-height: 26px;
      color: #262626;
    }
    .p-box {
      position: absolute;
      bottom: 24px;
      left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 24px;
      .top-control {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .module-title {
          font-size: 16px;
          color: #262626;
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
            width: 20px;
            height: 20px;
          }
        }
        .btn-group {
          display: flex;
          img {
            cursor: pointer;
            margin-left: 20px;
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    .ul {
      width: 100% !important;
      ::v-deep .swiper-slide {
        display: flex;
        width: 100%;
      }
      .li {
        margin-right: 2%;
        margin-top: 2%;
        width: 49%;
        height: 74px;
        background: #e9f3ff;
        padding: 8px 16px;
        .subtitle {
          font-size: 14px;
          color: #262626;
          line-height: 20px;
          padding-bottom: 8px;
        }
        .text {
          font-size: 12px;
          color: #595959;
          line-height: 16px;
        }
      }
      .li:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>

<template>
  <div class="pd-content" ref="block3">
    <div class="rec-pat container-box">
      <div class="right-box">
        <h6>远程医学教育</h6>
        <p class="p-text">
          系统用于教学指导、业务学习、技能培训和行业会议课程的录播、点播。利用远程平台实现数据信息的多角度、多元化呈现，创新教学模式拓展医学教育广度和深度，经典案例和手术示教视频录制在存储服务系统，可任何时间、地点通过浏览器点击播放反复巩固学习成果，考试模块对海量题库资源进行管理、更新、补充，同时设置公开题库供用户进行题库学习，有效培养高质量人才，拓展医师队伍，提高医疗业务能力，加快实现医院高质量发展。
        </p>
        <div class="p-box">
          <div class="top-control">
            <div class="module-title">
              <img src="~@/assets/img/images/cl.png" alt="" />
              <span>核心功能</span>
            </div>
            <div class="btn-group">
              <img
                @click="toSlide(0, 1)"
                src="~@/assets/img/images/active1.png"
                alt=""
              />
              <img
                @click="toSlide(0, 2)"
                src="~@/assets/img/images/active2.png"
                alt=""
              />
            </div>
          </div>
          <swiper class="ul" :options="swiperOption4" ref="mySwiper4">
            <swiper-slide>
              <div class="li">
                <p class="subtitle">人工管理</p>
                <p class="text">
                  扫描快速、图像清晰、多类型样本扫描、双物镜配置
                </p>
              </div>
              <div class="li">
                <p class="subtitle">课程管理</p>
                <p class="text">病人信息、临床信息、病嘱、历史病例</p>
              </div>
            </swiper-slide>

            <swiper-slide>
              <div class="li">
                <p class="subtitle">课件管理</p>
                <p class="text">会诊病例、未签发病例、签发病例、医嘱病例</p>
              </div>
              <div class="li">
                <p class="subtitle">学分管理</p>
                <p class="text">创建、取消、参加、短信、移动通知</p>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="left-box">
        <!-- <img src="~@/assets/img/images/education.png" alt="" /> -->
        <img
          src="https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/education.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      idx: 0,
      swiperOption4: {
        loop: true, // 循环
        speed: 500, //切换速度
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        centeredSlides: true,
        // slidesPerView: 1,
        on: {
          slideChange: () => {
            this.idx = this.swiper.realIndex;
          }
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper4.swiper;
    }
  },
  methods: {
    toSlide(idx, id) {
      console.log(idx, id);
      this.idx = idx;
      this.swiper.slideTo(id, 1000, false);
    }
  }
};
</script>

<style lang="scss" scoped>
.rec-pat {
  // margin-top: 80px;
  height: 480px;
  border: 1px solid #f0f0f0;
  display: flex;
  .left-box {
    width: 640px;
    height: 100%;
    // background: linear-gradient(
    //   108.19deg,
    //   #007aff -0.53%,
    //   rgba(255, 255, 255, 0) 49.5%
    // );
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right-box {
    position: relative;
    padding: 24px;
    flex: 1;
    height: 100%;
    h6 {
      font-size: 24px;
      color: #262626;
      line-height: 34px;
    }
    .p-text {
      padding-top: 10px;
      font-size: 16px;
      line-height: 26px;
      color: #262626;
    }

    .p-box {
      position: absolute;
      bottom: 24px;
      left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 24px;
      .top-control {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .module-title {
          font-size: 16px;
          color: #262626;
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
            width: 20px;
            height: 20px;
          }
        }
        .btn-group {
          display: flex;
          img {
            cursor: pointer;
            margin-left: 20px;
            width: 40px;
            height: 40px;
          }
        }
      }
    }
    .ul {
      width: 100% !important;
      ::v-deep .swiper-slide {
        display: flex;
        width: 100%;
      }
      .li {
        margin-right: 2%;
        margin-top: 2%;
        width: 49%;
        height: 74px;
        background: #e9f3ff;
        padding: 8px 16px;
        .subtitle {
          font-size: 14px;
          color: #262626;
          line-height: 20px;
          padding-bottom: 8px;
        }
        .text {
          font-size: 12px;
          color: #595959;
          line-height: 16px;
        }
      }
      .li:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>

<template>
  <div class="home">
    <div class="rco-box">
      <!-- <img ref="img1" src="~@/assets/img/images/rco.png" alt="" /> -->
      <img
        ref="img1"
        src="https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/rco.png"
        alt=""
      />
      <div class="container-box dv-position">远程协同平台</div>
    </div>
    <div class="banner-box">
      <!-- <img src="~@/assets/img/images/rco-banner1.png" alt="" /> -->
      <img
        src="https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/rco-banner1.png"
        alt=""
      />
      <div class="container-box p-position">
        <p class="p1 pdt">
          平台的建设基于服务器部署在中心医院端，通过前置机把平台功能和服务面向协作医院开放，以云计算、大数据、物联网、移动互联网为技术支撑、人工智能为载体、信息安全为辅助。
        </p>
        <p class="p1">
          实现远程病理诊断、细胞学病理AI辅助诊断、远程病理质控、远程培训、远程医学教育、远程胎心监护等医疗应用服务，为医院互联网诊疗业务开展提供快速、全面的支撑平台，还可以进行健康宣教，提升居民健康意识，医生社区促进医生学习和交流提升自我。远程协同平台实现资源整合和优化，解决医疗服务能力和水平差异过大难题，提升居民就医体验，实现高端医疗均质化。
        </p>
      </div>
    </div>
    <RemotePathological />
    <Diagnose />
    <Telemedicine />
    <MedicineEducation />
    <FetalHeart />
    <Precipach />
    <HealthMission />
    <Doctor />
    <Footer />
  </div>
</template>

<script>
import TopBg from "@/components/TopBg.vue";
import RemotePathological from "@/components/RemotePathological.vue";
import Diagnose from "@/components/Diagnose.vue";
import Telemedicine from "@/components/Telemedicine.vue";
import MedicineEducation from "@/components/MedicineEducation.vue";
import Precipach from "@/components/Precipach.vue";
import Doctor from "@/components/Doctor.vue";
import FetalHeart from "@/components/FetalHeart.vue";
import HealthMission from "@/components/HealthMission.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "RemoteCollaborative",
  components: {
    TopBg,
    RemotePathological,
    Diagnose,
    Telemedicine,
    MedicineEducation,
    Precipach,
    Doctor,
    FetalHeart,
    HealthMission,
    Footer
  },
  data() {
    return {};
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.rco-box {
  margin-top: 64px;
  width: 100%;
  height: 100%;
  // min-height: 240px;
  display: flex;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
}
.dv-position {
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  font-size: 46px;
  color: #fff;
}
.p-position {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  .p1 {
    font-size: 16px;
    line-height: 26px;
    color: #262626;
    width: 970px;
    text-indent: 2em;
    // text-align: center;
  }
  .pdt {
    padding-top: 3%;
  }
}
.banner-box {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
}
</style>

<template>
  <div class="pd-content" ref="block0">
    <div class="rec-pat container-box">
      <div class="left-box">
        <swiper :options="swiperOption" ref="mySwiper1">
          <swiper-slide
            class="swiper-slide"
            v-for="(item, index) in imgList"
            :key="item.id"
          >
            <img class="img" :src="item.icon" alt="" />
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
      <div class="right-box">
        <h6>细胞学病理AI辅助诊断</h6>
        <p>
          是对脱落细胞样本的显微图像进行观察、筛选、标记及分析，利用计算机辅助医生进行细胞学诊断，丁彦青教授及其团队作图像标注和专业指导，确保系统辅助判读结果置信度，基于深度学习提取病理图像特征，提高分析准确性，样本数据来源广泛，细胞量超80亿涵盖罕见病例。辅助诊断系统减轻病理医生60%繁琐重复的工作，提高医生工作效率，缓解我国病理医生紧缺问题，提高宫颈筛查诊断速度和准确率，加快国家普及“两癌筛查”项目进程。
        </p>
        <div class="view-detail">
          <span @click="viewFlow">查看诊断全流程</span>
        </div>
        <div class="p-box">
          <div
            class="flow-box"
            @click="toSlide(0, 1)"
            :class="idx == '0' ? 'active' : ''"
          >
            <span class="sp1">1</span>
            <span class="sp2">多模块选择</span>
          </div>
          <div class="line"></div>
          <div
            class="flow-box"
            @click="toSlide(1, 2)"
            :class="idx == '1' ? 'active' : ''"
          >
            <span class="sp1">2</span>
            <span class="sp2">总览</span>
          </div>
          <div class="line"></div>
          <div
            class="flow-box"
            @click="toSlide(2, 3)"
            :class="idx == '2' ? 'active' : ''"
          >
            <span class="sp1">3</span>
            <span class="sp2">10~80X倍数实时分析</span>
          </div>
        </div>
      </div>
    </div>

    <div class="view-flow-mask" v-if="isShowFlow" @click.stop="closeFlow">
      <div class="first-box" @click.stop="returnNull">
        <div class="close" @click.stop="closeFlow">
          <img src="~@/assets/img/images/close.png" alt="" />
        </div>
        <div class="view-flow-box" v-viewer>
          <img class="img1" src="~@/assets/img/images/flow.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      idx: 0,
      isShowFlow: false,
      imgList: [
        {
          id: 1,
          // icon: require("../assets/img/images/rec-pat1.png")
          icon:
            "https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/rec-pat1.png"
        },
        {
          id: 2,
          // icon: require("../assets/img/images/rec-pat2.png")
          icon:
            "https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/rec-pat2.png"
        },
        {
          id: 3,
          // icon: require("../assets/img/images/rec-pat3.png")
          icon:
            "https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/rec-pat3.png"
        }
      ],
      swiperOption: {
        loop: true, // 循环
        speed: 500, //切换速度
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        centeredSlides: true,
        // slidesPerView: 1,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        on: {
          slideChange: () => {
            // console.log(this.swiper.realIndex, "realIndex");
            this.idx = this.swiper.realIndex;
          }
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper1.swiper;
    }
  },

  methods: {
    toSlide(idx, id) {
      this.idx = idx;
      this.swiper.slideTo(id, 1000, false);
    },
    returnNull() {
      return false;
    },
    viewFlow() {
      this.isShowFlow = true;
    },
    closeFlow() {
      this.isShowFlow = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.rec-pat {
  // margin-top: 80px;
  height: 480px;
  border: 1px solid #f0f0f0;
  display: flex;
  .left-box {
    cursor: pointer;
    width: 640px;
    height: 100%;
    background: linear-gradient(
      153.43deg,
      #007aff 0%,
      rgba(255, 255, 255, 0) 83.33%
    );
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right-box {
    position: relative;
    padding: 24px;
    flex: 1;
    height: 100%;
    h6 {
      font-size: 24px;
      color: #262626;
      line-height: 34px;
    }
    p {
      padding-top: 10px;
      font-size: 16px;
      line-height: 26px;
      color: #262626;
    }
    .view-detail {
      font-size: 16px;
      color: #007aff;
      padding-top: 24px;
      padding-bottom: 30px;
      span {
        cursor: pointer;
      }
    }
    .p-box {
      position: absolute;
      bottom: 24px;
      left: 24px;
    }
    .flow-box {
      cursor: pointer;
      display: flex;
      align-items: center;
      .sp1 {
        width: 24px;
        height: 24px;
        background: #bfbfbf;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #fff;
        position: relative;
      }

      .sp2 {
        font-size: 14px;
        padding-left: 12px;
      }
    }
    .active .sp1 {
      background: #007aff;
    }
    .active .sp1::before {
      content: "";
      border-radius: 100%;
      border: 1px solid #007aff;
      width: 32px;
      height: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .active .sp2 {
      color: #262626;
    }
    .line {
      margin: 5px 11px;
      width: 2px;
      height: 15px;
      background: #bfbfbf;
    }
  }
}

.left-box:hover .swiper-button-prev,
.left-box:hover .swiper-button-next {
  display: block;
}
.swiper-button-prev,
.swiper-button-next {
  display: none;
}

.view-flow-mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  .view-flow-box {
    width: 720px;
    background: #fff;
    height: 600px;
    overflow: auto;
    .img1 {
      width: 100%;
      height: auto;
    }
    .img2 {
      width: 576px;
      height: 813px;
    }
  }
}

.view-flow-box::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.view-flow-box::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
.view-flow-box::-webkit-scrollbar-track {
  /*滚动条里面轨道  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);*/
  border-radius: 10px;
  background: #fff;
}
.first-box {
  position: relative;
  .close {
    width: 32px;
    height: 32px;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    right: 10px;
    cursor: pointer;
    img {
      width: 32px;
      height: 32px;
    }
  }
}
</style>

<template>
  <div class="pd-content" ref="block5">
    <div class="swiper-box container-box">
      <div class="rec-pat container-box">
        <div class="right-box">
          <h6>远程病理质控</h6>
          <p class="p-text">
            多终端登录病理质控系统，病理医师随时随地对检查结果复核后签发病理报告，减少具有临床执业资格和资质要求的医师不在科室就不能签发病理诊断报告的情况出现，提高出诊断报告效率。系统围绕病理检查前、中、后不同环节进行全程质控，通过远程平台实时数据分析展示，实现每一个环节的标准化操作和全流程质量控制。通过标准化报告模板强化病理诊断报告质控，医生点选就能快速输出标准化病理报告，不断提升业务质量。
          </p>
          <!-- <div class="module-title">
                <img src="~@/assets/img/images/cl.png" alt="" />
                <span>核心功能</span>
              </div>
              <ul>
                <li>
                  <p class="subtitle">数字化切片扫描</p>
                  <p class="text">
                    扫描快速、图像清晰、多类型样本扫描、双物镜配置
                  </p>
                </li>
                <li>
                  <p class="subtitle">病例管理</p>
                  <p class="text">病人信息、临床信息、病嘱、历史病例</p>
                </li>
                <li>
                  <p class="subtitle">标本信息</p>
                  <p class="text">检查项目、取材部位、大小、数量</p>
                </li>
                <li>
                  <p class="subtitle">数字阅片</p>
                  <p class="text">放大、缩小、自动采图、快速检索病例</p>
                </li>
              </ul> -->
        </div>
        <div class="left-box">
          <!-- <img src="~@/assets/img/images/precipach.png" alt="" /> -->
          <img
            src="https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/precipach.png"
            alt=""
          />
        </div>
      </div>

      <!-- <div class="button-box">
        <img class="img1" src="~@/assets/img/images/active1.png" alt="" />
        <div
          class="dv-center"
          :style="{ width: `calc(${this.px}px*10px )` }"
        >
          <div class="swiper-pagination" slot="pagination"></div>
        </div>
        <img src="~@/assets/img/images/active2.png" alt="" />
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.swiper-box {
  position: relative;
  .button-box {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 99;
    left: 24px;
    bottom: 24px;
    img {
      cursor: pointer;
      width: 40px;
      height: 40px;
    }
    .dv-center {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    // .img1 {
    //   margin-right: 30px;
    // }
  }
}
.rec-pat {
  // margin-top: 80px;
  height: 480px;
  border: 1px solid #f0f0f0;
  display: flex;
  .left-box {
    width: 640px;
    height: 100%;
    // background: linear-gradient(
    //   234.87deg,
    //   #007aff 0%,
    //   rgba(255, 255, 255, 0) 70.65%
    // );
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right-box {
    padding: 24px;
    flex: 1;
    height: 100%;
    h6 {
      font-size: 24px;
      color: #262626;
      line-height: 34px;
    }
    .p-text {
      padding-top: 10px;
      font-size: 16px;
      line-height: 26px;
      color: #262626;
    }
    .module-title {
      padding-top: 30px;
      font-size: 16px;
      color: #262626;
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        margin-right: 2%;
        margin-top: 2%;
        width: 49%;
        height: 74px;
        background: #e9f3ff;
        padding: 8px 16px;
        .subtitle {
          font-size: 14px;
          color: #262626;
          line-height: 20px;
          padding-bottom: 8px;
        }
        .text {
          font-size: 12px;
          color: #595959;
          line-height: 16px;
        }
      }
      li:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>
